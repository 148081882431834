import { initializeApp } from "firebase/app";
import { getAuth, signOut, onAuthStateChanged, connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Point to Firebase Emulators if running locally
// if (window.location.hostname === "localhost" || window.location.hostname === "tattooed.dev") {

//   // Point to the Authentication emulator running on localhost
//   connectAuthEmulator(auth, "http://localhost:9099");

//   // Connect to Firestore emulator
//   connectFirestoreEmulator(db, "localhost", 8080);

//   // Connect to Storage emulator
//   connectStorageEmulator(storage, "localhost", 9199);

//   // Add similar lines for other Firebase services you are using
// }


export { app, auth, signOut, onAuthStateChanged, db, storage, functions };
